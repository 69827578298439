<template>
    <div id="admin-login">
        <div class="login-container">
            <div class="row">
                <div class="logo"></div>
            </div>
            <div class="row title-login">{{ $t('admin.login.title') }}</div>
            <div class="row subtitle">{{ $t('admin.login.subtitle') }}</div>
            <template v-if="!externalLogin && !resetPassword">
                <div class="row" v-if="errors.login">
                    <Message type="error" :msg="errors.login"></Message>
                </div>
                <div class="row link" v-if="isExternal && !resetPassword">
                    <Message type="" :msg="$t('admin.login.remember')" v-on:clicked="goToExternalLogin()"></Message>
                </div>
                <div class="row">
                    <input class="user" type="text" v-model.trim="user" :placeholder="$t('admin.login.email')" :class="{ error: errors.user }" v-on:keyup.enter="login()" />
                </div>
                <div class="row">
                    <input class="password" type="password" v-model.trim="password" :placeholder="$t('admin.login.password')" :class="{ error: errors.password }" v-on:keyup.enter="login()" />
                </div>
                <div class="row">
                    <div class="action" @click="login()">{{ $t('admin.login.action') }}</div>
                </div>
                <div class="row">
                    <div class="description">{{ $t('admin.login.footer.part1') }}</div>
                </div>
                <div class="row">
                    <div class="description">{{ $t('admin.login.footer.part2') }}</div>
                </div>
                <div class="row">
                    <div class="description">{{ $t('admin.login.footer.part3') }}</div>
                </div>
            </template>
            <template v-else-if="externalLogin">
                <div class="row" v-if="errors.unknownUser">
                    <Message type="error" :msg="errors.unknownUser"></Message>
                </div>
                <div class="row description">
                    {{ $t('admin.login.description') }}
                </div>
                <div class="row">
                    <input class="user" type="text" v-model.trim="user" :placeholder="$t('admin.login.email')" :class="{ error: errors.user }" v-on:keyup.enter="login()" />
                </div>
                <div class="row">
                    <div class="action" @click="rememberPassword()">{{ $t('admin.login.remember_password') }}</div>
                </div>
                <div class="row">
                    <div class="turn-back" @click="turnBack()" v-html="$t('admin.login.turn_back')"></div>
                </div>
            </template>
            <template v-else-if="resetPassword">
                <div class="row description">
                    {{ $t('admin.login.reset_description') }}
                </div>
                <div class="row" v-if="errors.notEqualPassword">
                    <Message type="error" :msg="$t('admin.login.notEqualPassword')"></Message>
                </div>
                <div class="row" v-if="errors.weakPass">
                    <Message type="error" :msg="$t('admin.login.weak_pass')"></Message>
                </div>
                <div class="row">
                    <input class="password" :class="{ error: errors.notEqualPassword }" type="password" v-model.trim="password" :placeholder="$t('admin.login.password')" v-on:keyup.enter="newPassword()" />
                </div>
                <div class="row">
                    <input class="password" type="password" v-model.trim="password2" :placeholder="$t('admin.login.password_repeat')" :class="{ error: errors.notEqualPassword }" v-on:keyup.enter="newPassword()" />
                </div>
                <div class="row">
                    <div class="action" @click="newPassword()">{{ $t('admin.login.reset') }}</div>
                </div>
                <div class="row">
                    <div class="turn-back" @click="goToLogin()" v-html="$t('admin.login.reset_turn_back')"></div>
                </div>
            </template>
        </div>
        <div class="firm-container">
            <a href="https://intowin.io/" target="_blank" class="firm" v-html="$t('admin.login.firm')"></a>
        </div>
    </div>
</template>

<script>
const sha256 = require('js-sha256')
import Message from '@/components/ui/Message.vue'
import i18n from '@/i18n'

export default {
    name: 'AdminLogin',
    components: {
        Message
    },
    data() {
        return {
            user: '',
            password: '',
            password2: '',
            errors: {
                user: false,
                password: false,
                password2: false,
                login: '',
                notEqualPassword: false,
                weakPass: false,
                unknownUser: false
            },
            form: true,
            isExternal: false,
            externalLogin: false,
            resetPassword: false,
            passwordRegex: /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/
        }
    },
    methods: {
        rememberPassword() {
            this.errors.unknownUser = false

            var self = this
            this.$overlay.show()
            this.$store.dispatch('rememberPassword', { email: this.user }).then(function (response) {
                if (response.status) {
                    self.$overlay.hide()
                    self.turnBack()
                    self.$snackbar.success({
                        message: i18n.t('admin.email_confirmation'),
                        customClass: 'success',
                        duration: 5000
                    })
                } else {
                    self.$overlay.hide()
                    self.errors.unknownUser = response.error.msg
                }
            })
        },
        goToLogin() {
            this.resetPassword = false
            this.externalLogin = false
            this.isExternal = false
            this.$router.push({ name: 'Login' })
        },
        newPassword() {
            // this.passwordRegex.test(this.password)
            Object.keys(this.errors).forEach((v) => (this.errors[v] = false))
            this.form = true
            if (this.password != this.password2) {
                this.errors.notEqualPassword = true
                this.form = false
            }
            if (!this.passwordRegex.test(this.password)) {
                this.form = false
                this.errors.weakPass = true
            }
            if (this.form) {
                var params = {
                    password: sha256(this.password),
                    userID: this.$route.params.id
                }
                var self = this
                this.$store
                    .dispatch('access/changePassword', params)
                    .then(function (result) {
                        if (!result.status) {
                            console.log(result)
                        } else {
                            self.user = result.data
                            setTimeout(function () {
                                self.login()
                            }, 200)
                        }
                    })
                    .catch(function () {
                        self.errors.pasword = self.$t('public.errors.api')
                    })
            }
        },
        turnBack() {
            this.externalLogin = false
            this.password = ''
            this.errors.password = false
            this.errors.login = false
            this.user = ''
            this.isExternal = false
        },
        goToExternalLogin() {
            this.externalLogin = true
            this.errors.user = false
        },
        async login() {
            let validate = true
            var regexp = new RegExp('(@es.mcd.com)')
            var name = this.user
            const isExternal = regexp.exec(name)

            this.errors.user = false
            this.errors.password = false
            this.errors.login = ''

            if (this.user == '') {
                this.errors.user = true
                validate = false
            }

            if (this.password == '') {
                this.errors.password = true
                validate = false
            }

            if (validate) {
                this.$overlay.show()

                const params = {
                    user: this.user,
                    password: isExternal ? this.password : sha256(this.password)
                }

                const result = await this.$store.dispatch('login', params)
                this.$overlay.hide()

                if (result.status) {
                    this.$store.commit('setToken', result.data)
                    this.$router.push({ name: 'Bills' })
                    this.$store.dispatch('init')
                } else {
                    this.errors.user = true
                    this.errors.password = true
                    this.errors.login = result.error.msg
                    if (!regexp.exec(name)) {
                        this.isExternal = true
                    }
                }
            }
        },
        async miTurnoLogin() {
            this.$overlay.show()

            const params = {
                id1: this.$route.params.id1,
                id2: this.$route.params.id2
            }

            const result = await this.$store.dispatch('miTurnoLogin', params)

            this.$overlay.hide()

            if (result.status) {
                this.$store.commit('setToken', result.data)
                this.$router.push({ name: 'Bills' })
                this.$store.dispatch('init')
            } else {
                this.errors.user = true
                this.errors.password = true
                this.errors.login = result.error.msg
            }
        }
    },
    created() {
        localStorage.removeItem('token')
        // console.log(this.$route.name ==)
        if (this.$route.name == 'ResetPassword') {
            this.resetPassword = true
        }

        if (this.$route.name == 'MiTurnoLogin') {
            this.miTurnoLogin()
        }
    }
}
</script>

<style lang="scss">
#admin-login {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;

    .login-container {
        height: calc(100% - 30px);
        width: 100%;
        padding-top: 100px;
        display: inline-block;

        .row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .logo {
                background-image: url('../../public/img/logo.png');
                background-size: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                width: 90px;
                height: 100px;
                margin-bottom: 32px;
            }

            &.title-login {
                font-family: Speedee-bd;
                font-size: 32px;
            }

            &.subtitle {
                font-family: Speedee;
                font-size: 26px;
                margin-bottom: 25px;
            }
            .turn-back {
                display: flex;
                font-family: Speedee;

                .link-back {
                    font-family: Speedee;
                    padding-left: 6px;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            input {
                width: 400px;
                height: 80px;
                background: #fff;
                border: 2px solid #bebebe;
                border-radius: 4px;
                font-family: Speedee;
                font-size: 24px;
                padding: 0 30px;

                &.user {
                    margin-bottom: 14px;
                }

                &.password {
                    margin-bottom: 24px;
                }

                &.error {
                    border: 2px solid $red;
                    background-color: $red2;
                }
            }

            .action {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 400px;
                height: 80px;
                background: #ffbc0d;
                border-radius: 4px;
                font-size: 30px;
                font-family: Speedee-bd;
                cursor: pointer;
                margin-bottom: 66px;
            }

            .description {
                font-family: Speedee;
                font-size: 18px;
                color: #222;
            }
            &.link {
                cursor: pointer;
            }
            &.description {
                padding-bottom: 50px;
                padding-top: 20px;
                font-family: Speedee-lt;
                font-size: 18px;
                color: #222;
            }
        }
    }

    .firm-container {
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .firm {
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            text-align: center;
            color: #949494;
            font-size: 11px;
            font-family: Speedee;
            text-decoration: none;
        }
    }
}
</style>
